export default class Pace {

    /** The distance for this pace in meters */
    distance: number | null = null
    minutes: number | null = null
    seconds: number | null = null

    constructor(totalSeconds: number | null, distance: number | null) {
        if (totalSeconds != null) {
            this.updatePace(totalSeconds)
        }
        this.distance = distance
    }

    updatePace(seconds: number) {
        const tempDate = new Date(seconds * 1000)
        this.minutes = tempDate.getMinutes()
        this.seconds = tempDate.getSeconds()
    }

    getTotalSeconds(): number {
        return (this.minutes || 0) * 60 + (this.seconds || 0)
    }

    getPacePerKilometer(): Pace {
        if (this.distance === null) {
            return this
        }

        if (this.distance < 1000) {
            const multiplier = 1000 / this.distance
            return new Pace(this.getTotalSeconds() * multiplier, 1000)
        }

        if (this.distance > 1000) {
            const multiplier = this.distance / 1000
            return new Pace(this.getTotalSeconds() / multiplier, 1000)
        }

        return this
    }

    subtractSeconds(secondsToSubstract: number): void {

        if (this.seconds == null) throw new Error('Seconds have not been set.')

        // Substract the number of seconds
        const newSeconds = (((this.minutes || 0) * 60) + this.seconds) - secondsToSubstract
        if (newSeconds < 0) {
            this.seconds = 0
            this.minutes = 0
            return
        }

        // Use the date object to convert it pack to a pace
        this.updatePace(newSeconds)
    }
}