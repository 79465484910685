import Pace from "../data/Pace";
import PaceCalculator, { PaceChangedHandler } from "./PaceCalculator";

export default class TrackTepmoPaceCalculator implements PaceCalculator {

    paceChangedHandlers: PaceChangedHandler[] = new Array()

    calculatedPaces: Pace[] = new Array()    
    basePace: Pace = new Pace(null, null)

    setBasePace(pace: Pace): void {        

        // This input is a 10K pace
        this.basePace = new Pace(pace.getTotalSeconds() / 10, 1000)

        // Calculate the new paces
        this.updatePaces()
        
        // Fire the pace changed handlers
        this.paceChangedHandlers.forEach(handler => {
            handler(this.basePace)
        });
    }

    subscribeToPaceChanges(handler: PaceChangedHandler): void {
        this.paceChangedHandlers.push(handler)
    }

    unsubscribeToPaceChanges(handler: PaceChangedHandler): void {
        const indexToDelete = this.paceChangedHandlers.indexOf(handler)
        if (indexToDelete >= 0) {
            this.paceChangedHandlers.splice(indexToDelete, 1)
        }
        else {
            console.warn('Could not delete the handler since it could not be found.')
        }
    }

    updatePaces(): void {

        let paces: Pace[] = new Array()
        
        // Calculate all the paces
        paces.push(new Pace(this.basePace.getTotalSeconds() * 3, 3000))        
        paces.push(new Pace((this.basePace.getTotalSeconds() - 2) * 2, 2000))

        // Calculate the 1K pace and keep it available as we need it for other pace calculations
        const thousandMeterPace = new Pace((this.basePace.getTotalSeconds() - 6), 1000)
        paces.push(thousandMeterPace)        

        // All other calculations are done based on the 100M pace
        const hundredMeterPace = new Pace(thousandMeterPace.getTotalSeconds() / 10, 100)

        paces.push(new Pace((hundredMeterPace.getTotalSeconds() * 8) - 2, 800))
        paces.push(new Pace((hundredMeterPace.getTotalSeconds() * 6) - 3, 600))
        paces.push(new Pace((hundredMeterPace.getTotalSeconds() * 4) - 4, 400))
        paces.push(new Pace((hundredMeterPace.getTotalSeconds() * 3) - 5, 300))
        paces.push(new Pace((hundredMeterPace.getTotalSeconds() * 2) - 6, 200))

        this.calculatedPaces = paces
    }



}