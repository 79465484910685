import './App.css'

import React, { useEffect, useState } from 'react'
import TimeInput from './components/TimeInput'
import TrackTepmoPaceCalculator from './logic/TrackTepmoPaceCalculator';
import Pace from './data/Pace';
import TrackPaceDisplay from './components/TrackPaceDisplay';
import { Paper, Typography } from '@mui/material';

const trackTempoPaceCalculator: TrackTepmoPaceCalculator = new TrackTepmoPaceCalculator()

function App() {

  return (
    <div className="App">

      <Paper className='pace-paper'>
        <div className='pace-paper-content'>
          <Typography variant='h4'>Pace calculator</Typography>
          <Typography variant='body1'>Enter your 10K time to calculate your track paces:</Typography>
        </div>
        <div className='pace-paper-content'>
          <TimeInput onTimeChanged={(minutes: number, seconds: number) => trackTempoPaceCalculator.setBasePace(new Pace((minutes * 60) + seconds, 10000))} />
        </div>
      </Paper>
      <TrackPaceDisplay paceCalculator={trackTempoPaceCalculator} />

      <div className='pace-paper pace-paper-content pay-off'>
        <Typography variant='subtitle2'>Build by Matthijs Koopman</Typography>
      </div>
    </div>
  );
}

export default App;
