import Pace from "../data/Pace"

export function padLeft(value: number, length: number, padding: String): String {
    let stringValue = value.toString()
    while (stringValue.length < length) {
        stringValue = padding + stringValue
    }
    return stringValue
}

export function toTrackPace(value: Pace): String {

    if (value.distance == null) return ''

    // Everything over 400 meters will display as an interval per 400 meter
    if (value.distance >= 400) {

        const modifier = Number.parseFloat((value.distance / 400).toFixed(1))
        const intervalPace = new Pace(Number.parseInt((value.getTotalSeconds() / modifier).toFixed(0)), 400)

        return `${padLeft(intervalPace.minutes || 0, 2, '0')}:${padLeft(intervalPace.seconds || 0, 2, '0')} per ${intervalPace.distance} meter`
    }

    // Everything under 100 meters will display as an interval per 100 meter
    const modifier = Number.parseFloat((value.distance / 100).toFixed(1))
    const intervalPace = new Pace(Number.parseInt((value.getTotalSeconds() / modifier).toFixed(0)), 100)

    return `${padLeft(intervalPace.minutes || 0, 2, '0')}:${padLeft(intervalPace.seconds || 0, 2, '0')} per ${intervalPace.distance} meter`
}

export function toMinuteSeconds(value: Pace): String {
        return `${padLeft(value.minutes || 0, 2, '0')}:${padLeft(value.seconds || 0, 2, '0')}`
}