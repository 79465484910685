import './TimeInput.css'

import React, { useEffect, useState } from 'react'
import { TextField } from '@mui/material'
import TimeInputProps from './TimeInputProps';

const TimeInput = (props: TimeInputProps) => {
    const [minutes, setMinutes] = useState<number>(0)
    const [seconds, setSeconds] = useState<number>(0)

    useEffect(() => {
        if (props.onTimeChanged) {
            props.onTimeChanged(minutes, seconds)
        }
    }, [minutes, seconds])

    return (
        <>
            <TextField
                id='minutes'
                label='Minutes'
                type='number'
                variant='standard'
                value={minutes}
                onChange={(e) => {
                    setMinutes(Math.max(0, Number.parseInt(e.target.value)))
                }}
                onBlur={(e) => {
                    if (!e.target.value) {
                        setSeconds(0)
                    }
                }} />

            <TextField
                id='seconds'
                label='Seconds'
                type='number'
                variant='standard'
                value={seconds}
                onChange={(e) => {
                    setSeconds(Math.min(Math.max(0, Number.parseInt(e.target.value)), 59))
                }}
                onBlur={(e) => {
                    if (!e.target.value) {
                        setSeconds(0)
                    }
                }}
            />
        </>
    )
}

export default TimeInput;