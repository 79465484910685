import './TrackPaceDisplay.css'

import React, { useEffect, useState } from 'react'
import TrackPaceDisplayProps from "./TrackPaceDisplayProps";
import Pace from '../../data/Pace';
import { PaceChangedHandler } from '../../logic/PaceCalculator';
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { padLeft, toMinuteSeconds, toTrackPace } from '../../helpers/FormatHelper';

const TrackPaceDisplay = (props: TrackPaceDisplayProps) => {

    const [paces, setPaces] = useState<Pace[]>(new Array())

    useEffect(() => {

        const paceCalculator = props.paceCalculator

        // Subscribe to pace changes
        const handlePaceChanged: PaceChangedHandler = (_) => {
            console.debug('Updating paces')
            setPaces(paceCalculator.calculatedPaces)
        }

        paceCalculator.subscribeToPaceChanges(handlePaceChanged)

        return () => {
            paceCalculator.unsubscribeToPaceChanges(handlePaceChanged)
        }
    })

    return (
        <TableContainer component={Paper} className='pace-paper'>
            <Table className='track-pace-table' aria-label="Track pace table">
                <TableHead>
                    <TableRow>
                        <TableCell className='track-pace-header'>Distance</TableCell>
                        <TableCell className='track-pace-header' align="right">Time</TableCell>
                        <TableCell className='track-pace-header' align="right">Interval</TableCell>
                        <TableCell className='track-pace-header' align="right">min/km</TableCell>                        
                    </TableRow>
                </TableHead>
                <TableBody>
                    {paces.map((pace, i) => {
                        return (
                            <TableRow key={pace.distance + 'm'}>
                                <TableCell>{pace.distance} meter</TableCell>
                                <TableCell align="right">{padLeft(pace.minutes || 0, 2, '0')}:{padLeft(pace.seconds || 0, 2, '0')}</TableCell>
                                <TableCell align="right">{toTrackPace(pace)}</TableCell>
                                <TableCell align="right">{toMinuteSeconds(pace.getPacePerKilometer())}</TableCell>
                            </TableRow>                            
                        )
                    })}
                </TableBody>
            </Table>
        </TableContainer>
    )
}

export default TrackPaceDisplay